<template>
  <vx-card class="bank-menu-page main-box top-zero-radius"
           v-if="bank && Object.keys(bank).length > 0">

    <profile-main-box :profile="getBankProfile"
                      :default-action="profileStatus"
                      :actions-list="actionsList"
                      @transactionsTreasuryBank="profileStatus = 'transactionsTreasuryBank'"
                      @bankActivitiesLog="profileStatus = 'bankActivitiesLog'">
      <bank-transactions v-if="profileStatus === 'transactionsTreasuryBank'" :bank-id="parseInt($route.params.id)" />

      <bank-activities-log v-else-if="profileStatus === 'bankActivitiesLog'" />

      <edit-bank v-else-if="profileStatus === 'editBank'" :bank-id="parseInt($route.params.id)" />
    </profile-main-box>
    <button id="editBank" v-show="false" @click="profileStatus = 'editBank'"></button>
  </vx-card>
</template>

<script>
import {mapGetters} from "vuex";
import {getBank} from '@/http/requests/treasury/banks'
import ProfileMainBox from '@/components/profileMainBox/profileMainBox2'
import {customParseFloat} from "../../../../../../assets/js/functions";
import BankActivitiesLog from "../activitiesLog/bankActivitiesLog.vue";

export default {
  name: 'bankMenu',
  components: {
    BankActivitiesLog,
    EditBank: () => import("../edit/editBank.vue"),
    BankTransactions: () => import("../transactions/bankTransactions.vue"),
    ProfileMainBox
  },
  metaInfo () {
    return {
      title: this.bank ? this.$t('treasury.banks.bank.dynamicTitle', {bank: this.bank.name}) : this.$t('treasury.banks.bank.title')
    }
  },
  props: {
    bankId: {
      type: Number,
      default: () => { return 0 }
    }
  },
  data () {
    return {
      profileStatus: 'transactionsTreasuryBank',
      bank: null,
      actionsList: [
        {
          event: 'transactionsTreasuryBank',
          name: 'transactionsTreasuryBank',
          i18n: 'treasury.banks.bank.actions.transactions',
          icon: 'icon-layers',
          iconPack: 'feather'
        },
        {
          event: 'bankActivitiesLog',
          name: 'BankActivitiesLog',
          params: {id: this.bankId || this.$route.params.id},
          header: this.$t('treasury.banks.bank.actions.logs'),
          i18n: '',
          icon: 'LAST_ACTIVITIES',
          iconPack: 'useral',
          permission: 'activity_logs.show'
        },
      ],
      actions: {
        toolbar: [
          {
            id: 'editBank',
            icon: 'EDIT',
            iconPack: 'useral',
            color: 'warning',
            permission: 'bank.update'
          }
        ],
        leftToolbar: [
          {
            id: 'routeBackBTN',
            route: {name: 'treasuryBanks'},
            icon: 'CHEVRON_LEFT',
            iconPack: 'useral'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'isMobile'
    }),
    getBankProfile () {
      const bank = {
        avatar: this.bank.avatar ? this.bank.avatar : require('@/assets/images/pages/bank.jpg'),
        name: this.bank.name,
        details: [],
        importantData: [
          // {
          //   key: this.$t('treasury.banks.bank.importantData.balance'),
          //   value: this.bank.balance ? Math.abs(this.bank.balance) : 0,
          //   class: this.bank.balance < 0 ? 'text-danger' : 'text-success',
          //   type: 'price'
          // },
          {
            key: this.$t('treasury.banks.bank.importantData.openingBalance'),
            value: this.bank.opening_inventory ? this.bank.opening_inventory : 0,
            class: 'text-success',
            type: 'price'
          },
          {
            key: this.$t('treasury.banks.bank.importantData.transactionsCount'),
            value: this.bank.transaction_count || 0,
            class: 'text-primary'
          }
        ]
      }

      if (this.bank.card_number) bank.details.push({
        key: this.$t('treasury.banks.labels.cardNumber'),
        icon: 'icon-credit-card',
        iconPack: 'feather',
        value: this.bank.card_number.toString().replace(/\B(?=(\d{4})+(?!\d))/g, '-')
      })

      if (this.bank.account_number) bank.details.push({
        key: this.$t('treasury.banks.labels.accountNumber'),
        icon: 'icon-hash',
        iconPack: 'feather',
        value: this.bank.account_number.toString()
      })

      if (this.bank.shaba_number) bank.details.push({
        key: this.$t('treasury.banks.labels.shebaNumber'),
        icon: 'icon-hash',
        iconPack: 'feather',
        value: `IR${  this.bank.shaba_number.toString()}`
      })

      return bank
    }
  },
  created () {
    if (this.isMobile) {
      this.actions.toolbar[0].id = {name: 'editTreasuryBank', params: {id: this.$route.params.id}}
      this.actions.toolbar[0].type = 'link'
    }
    // console.log(this.$router.options.history.state)
    this.$nextTick(() => {
      this.$store.dispatch('updateNavbarActions', this.actions)
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', 'profile-page-navbar mx-auto')
    })

    this.getBank()
  },
  methods: {
    getBank () {

      getBank(this.bankId || this.$route.params.id).then(response => {
        this.bank = response.data.data
        if (!this._isBeingDestroyed) {
          this.$store.dispatch('setPageTitle', this.$t('treasury.banks.bank.dynamicTitle', {bank: this.bank.name}))
        }

        const balance_index = this.actionsList.map(e => e.event).indexOf('transactionsTreasuryBank')
        if (balance_index > -1) {
          this.actionsList[balance_index].extra = {
            value: customParseFloat(this.bank.balance || 0),
            type: 'price'
          }
        }
      })
    },
    handleClick (id) {
      document.getElementById(id).click()
    }
  }
}
</script>

<style lang="scss">
.bank-menu-page {

  .vx-card__body {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}
</style>
